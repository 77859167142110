import { NextPage } from "next";
import Router from "@/helpers/router";

const Error404Page: NextPage = () => {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="text-center">
        <h1 className="mb-6 text-6xl font-bold">404</h1>
        <div>
          <p>{`Page is not found!`}</p>
        </div>

        <a href={Router.logout()} className="btn btn-wide mt-10">
          Logout
        </a>
      </div>
    </div>
  );
};

export default Error404Page;
